<template>
  <div style="max-width:1000px;">
    <div class="maintitle">
      <span v-if="form.uuid==0">添加</span>
      <span v-else>修改</span>公告
    </div>
    <el-divider></el-divider>
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="编号" prop="id" style='display:none'>
        <el-input v-model="form.id" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" minlength="4" maxlength="50" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="详情" prop="detail">
        <el-input type='textarea' v-model="form.detail" maxlength="1000"></el-input>
      </el-form-item>
      <el-form-item label="修改地区" class="text-left">
        <el-checkbox-group v-model="form.arealist">
          <el-checkbox
            v-for="(item,index) in district"
            :key="index"
            :label="item.id+'-'+item.fullname"
            :value="item.id"
          >{{item.fullname}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <el-button @click="add">
      <span v-if="form.uuid==0">添加</span>
      <span v-else>修改</span>
    </el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        id: "", //公告id
        title: "", //公告标题
        detail: "", //公告内容
        arealist: [] //公告地区
      },
      rules: {
        
      },
      district: [] //县、区列表
    };
  },
  methods: {
    getinfo: function() {
      let id = this.$route.params.id;
      if (!id) {
        return;
      }
      this.$axios({
        url: this.HOST + "/jiameng/gonggao/getInfo",
        method: "post",
        data: { id: id }
      }).then(res => {
        if (res.data.status == 1) {
          this.form = res.data.info;
        }
      });
    },
    add: function() {
      console.log(this.form);
      this.$axios({
        url: this.HOST + "/jiameng/gonggao/addGonggao",
        method: "post",
        data: {
          info: this.form
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.$router.push("/gonggao");
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info
          });
        }
      });
    },
    getAreaList() {
      let url = this.HOST + "/jiameng/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.district = res.data.list;console.log(this.district);
        } else {
          return [];
        }
      });
    },
  },
  computed: {},
  created: function() {
    this.getinfo();
    this.getAreaList();
  }
};
</script>

<style>
</style>